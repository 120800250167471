.App {
    display: flex;
    flex-direction: row;
    height: 100vh;
    font-family: "FS Joey";
}

h2{
    font-size: xx-large;
}

p{
    font-size: large;
}

a{
    color: red;
}

.data-container {
    width: 90vw;
    padding-left: 30px;
    padding-top: 30px;
    overflow-y: scroll;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.api-link {
    margin-top: 10px;
    font-size: 12pt;
    transition: all 0.1s ease;
    padding-left: 10px;
}

.api-link:hover {
    cursor: pointer;
    border-left: 5px solid crimson;
    transition: all 0.1s ease;
}

.pdf-viewer{
    height: 45em;
    padding-right: 2%;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
