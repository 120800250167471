body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif, "FS Joey";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

@font-face {
  font-family: "FS Joey";
  src: url("//db.onlinewebfonts.com/t/fc7fd845ab9062a96aaa215295bf10b3.eot");
  src: url("//db.onlinewebfonts.com/t/fc7fd845ab9062a96aaa215295bf10b3.eot?#iefix") format("embedded-opentype"),
  url("//db.onlinewebfonts.com/t/fc7fd845ab9062a96aaa215295bf10b3.woff2") format("woff2"),
  url("//db.onlinewebfonts.com/t/fc7fd845ab9062a96aaa215295bf10b3.woff") format("woff"),
  url("//db.onlinewebfonts.com/t/fc7fd845ab9062a96aaa215295bf10b3.ttf") format("truetype"),
  url("//db.onlinewebfonts.com/t/fc7fd845ab9062a96aaa215295bf10b3.svg#FS Joey") format("svg");
}
