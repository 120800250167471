.avatar {
  //background: rgba(0, 0, 0, 0.1);
  padding: 1em 0.5em 0em;
  text-align: center;

  img {
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
  }

  h2 {
    font-weight: normal;
    margin-bottom: 0;
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    color: red;
  }
}

.side-bar {
  display: flex;
  flex-direction: column;
  width: 20vw;
  height: 100vh;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.2);
}

.side-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  color: whitesmoke;
  background-color: #2d383a;
  border-bottom: 5px solid crimson;
  text-align: center;

  h1 {
    margin: 0;
    font-size: 36pt;
  }
}

.side-bar-body {
  padding-left: 20px;
}

.selectedLink {
  color: red;
}
